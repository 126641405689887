import Insight from "../insightClass";
import React from "react";
import { insight39Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight039 = new Insight(
  "28 March 2025",
  {
    pageTitle: "Global Offshore Wind Weekly",
    metaDescription: "WeBridge Insights, Global Offshore Wind Weekly",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind Weekly",
  },
  {
    src: insight39Media[0],
    alt: "offshore wind turbine",
  },
  "Global Offshore Wind Weekly (2025-03-28)",
  "",
  `Swedish utility Vattenfall announced plans to construct the Nordlicht wind cluster in the German North Sea, comprising Nordlicht 1 and 2, with a combined capacity exceeding 1.6 GW. Nordlicht 1 will be Germany's largest offshore wind project, utilizing turbine towers partially made with low-emission steel to reduce carbon footprint.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>Global Offshore Wind Weekly (2025-03-28)</h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>28 March 2025</p>
      <br />
      <p>
        Welcome to this week's <i>WeBridge Insights</i>, where we provide you
        with the latest updates and key developments from the global offshore
        wind market.
      </p>
      <br />
      <br />
      <br />
      <p>
        At <b>WeBridge</b>, we deliver forward-thinking analysis and expert
        commentary to keep you informed in this fast-evolving industry. Here's
        our roundup of the top stories this week, complete with professional
        insights to help you stay ahead:
      </p>
      <h3>1. Vattenfall to Build Germany's Largest Offshore Wind Farm</h3>
      <p>
        Swedish utility Vattenfall announced plans to construct the Nordlicht
        wind cluster in the German North Sea, comprising Nordlicht 1 and 2, with
        a combined capacity exceeding 1.6 GW. Nordlicht 1 will be Germany's
        largest offshore wind project, utilizing turbine towers partially made
        with low-emission steel to reduce carbon footprint.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Vattenfall's initiative underscores a significant advancement in
        Germany's renewable energy sector, aligning with global decarbonization
        goals. The incorporation of low-emission steel in turbine construction
        sets a precedent for sustainable practices in offshore wind farm
        development.
      </p>
      <h3>2. BASF Projects €300 Million Q1 Impact from Wind Project Sale</h3>
      <p>
        German chemical company BASF anticipates a non-cash disposal loss of
        approximately €300 million in Q1 earnings due to the sale of its 49%
        stake in the Nordlicht wind cluster to Vattenfall. Despite the financial
        impact, BASF secures a long-term renewable electricity supply from the
        projects.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        BASF's divestment highlights the strategic balancing act companies face
        between financial performance and sustainability commitments,
        illustrating the complexities of integrating renewable energy assets
        into corporate portfolios. 
      </p>
      <h3>3. Elliott Management Acquires 5% Stake in RWE</h3>
      <p>
        Activist investor Elliott Management has acquired nearly a 5% stake in
        RWE, advocating for an accelerated share buyback program. This move
        follows RWE's recent €10 billion cut in capital expenditure on green
        technologies, a decision Elliott views positively for focused capital
        allocation.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Elliott's involvement may influence RWE's strategic direction,
        potentially prioritizing shareholder returns over expansive green
        investments, highlighting the tension between financial objectives and
        renewable energy ambitions. 
      </p>
      <h3>
        4. Ingka Investments, Oxan Energy, and Avapa Energy to Develop Offshore
        Wind Projects in Italy
      </h3>
      <p>
        Ingka Investments, Oxan Energy, and Avapa Energy have partnered to
        develop two floating offshore wind projects in Italy—Sicily South and
        Sardinia Northwest—with a combined potential capacity of 2.45 GW. These
        projects aim to contribute significantly to Italy's decarbonization
        efforts.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        This collaboration underscores the growing interest in floating offshore
        wind technology in the Mediterranean region. Leveraging the expertise of
        these partners could accelerate Italy's transition to renewable energy
        and set a precedent for similar projects in other markets.
      </p>
      <h3>5. Haizea Wind Group Reports Record Growth in 2024</h3>
      <p>
        Haizea Wind Group achieved a 32% revenue increase in 2024, reaching €371
        million, and a 211% surge in net profit to €18 million. The growth is
        attributed to strategic expansions and a robust order backlog from major
        clients like Iberdrola, Ørsted, Vestas, GE Vernova, and Siemens Gamesa.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Haizea's impressive financial performance highlights the escalating
        demand for offshore wind components. Their strategic partnerships and
        investments position them as a key player in the supply chain, crucial
        for meeting the industry's ambitious expansion targets.
      </p>
      <h3>
        6. Vena Energy Invests $200 Million in South Korean Offshore Wind
        Project
      </h3>
      <p>
        Vena Energy has committed $200 million to develop a 384 MW offshore wind
        project in South Korea, aiming to bolster the country's energy
        independence. The investment underscores the growing interest in
        offshore wind energy in the Asia-Pacific region.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Vena Energy's investment reflects the increasing momentum of offshore
        wind development in South Korea. This project could serve as a catalyst
        for further renewable energy initiatives in the region, contributing to
        energy diversification and sustainability goals.
      </p>
      <h3>7. Glamox to Illuminate Denmark's Largest Offshore Wind Farm</h3>
      <p>
        Glamox has secured a contract to provide lighting for the Thor wind
        farm, set to be Denmark's largest offshore wind farm. This project
        underscores the importance of specialized infrastructure in supporting
        large-scale renewable energy developments.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Glamox's involvement highlights the critical role of specialized
        suppliers in the offshore wind supply chain, contributing to the
        successful implementation of large-scale renewable energy projects. 
      </p>
      <br />
      <br />
      <br />
      <p>
        As the global offshore wind sector continues to advance, innovation,
        investments, and supportive policies are driving transformative growth.
        At{" "}
        <i>
          <b>WeBridge</b>
        </i>
        , we provide the expertise and insights our partners need to confidently
        navigate this evolving landscape.{" "}
      </p>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
