import Insight from "../insightClass";
import React from "react";
import { insight36Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight036 = new Insight(
  "7 March 2025",
  {
    pageTitle: "Global Offshore Wind Weekly",
    metaDescription: "WeBridge Insights, Global Offshore Wind Weekly",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind Weekly",
  },
  {
    src: insight36Media[0],
    alt: "offshore wind turbine",
  },
  "Global Offshore Wind Weekly (2025-03-07)",
  "",
  `According to research from Norway-headquartered Rystad Energy, the global offshore wind industry is set for a rebound in 2025, with capacity additions expected to reach 19 GW and sector-wide expenditure projected to hit USD 80 billion (approximately EUR 76 billion).`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>Global Offshore Wind Weekly (2025-03-07)</h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>7 March 2025</p>
      <br />
      <p>
        Welcome to this week's <i>WeBridge Insights</i>, where we provide you
        with the latest updates and key developments from the global offshore
        wind market.
      </p>
      <br />
      <br />
      <br />
      <p>
        At <b>WeBridge</b>, we deliver forward-thinking analysis and expert
        commentary to keep you informed in this fast-evolving industry. Here's
        our roundup of the top stories this week, complete with professional
        insights to help you stay ahead:
      </p>
      <h3>1. Global Offshore Wind Industry Set for Rebound in 2025</h3>
      <p>
        According to research from Norway-headquartered Rystad Energy, the
        global offshore wind industry is set for a rebound in 2025, with
        capacity additions expected to reach 19 GW and sector-wide expenditure
        projected to hit USD 80 billion (approximately EUR 76 billion).
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The anticipated growth in offshore wind capacity reflects the sector's
        resilience and adaptability. Strategic investments and supportive
        policies are crucial to sustaining this momentum and achieving global
        renewable energy targets.
      </p>
      <h3>
        2. Big Businesses Drive Renewable Energy Growth Through Direct
        Procurement
      </h3>
      <p>
        Major corporations are significantly increasing their purchases of wind
        and solar energy to support renewable energy projects, especially as
        governments scale back subsidies. According to BloombergNEF, the amount
        of renewable electricity sold through long-term power purchase
        agreements (PPAs) surged 35% in 2024, with the U.S. seeing the highest
        growth, primarily driven by tech companies powering their data centers.
      </p>
      <p>
        Amazon was the top buyer, while companies in the chemicals, mining, and
        raw materials sectors, particularly in developing countries, also
        contributed to rising demand.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Corporate procurement of renewable energy is becoming a key driver in
        the transition to clean energy. As government subsidies decline, PPAs
        offer crucial financial backing for new projects. This trend not only
        facilitates carbon emission reduction but also enhances the stability
        and attractiveness of global renewable energy investments.
      </p>
      <h3>
        3. UK Government Invests £55.7 Million in Scottish Port for Offshore
        Wind Expansion
      </h3>
      <p>
        The UK government has announced a £55.7 million investment to expand the
        Port of Cromarty Firth in Scotland. This development aims to transform
        the port into a hub for floating offshore wind turbines, aligning with
        Britain's goal to decarbonize its electricity sector by 2030. Floating
        turbines, which are not anchored to the seabed, can be positioned
        further offshore where wind speeds are stronger and visual impact is
        minimized. The port is expected to be operational by early 2028. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        This strategic investment underscores the UK's commitment to advancing
        offshore wind technology. Developing infrastructure for floating
        turbines is crucial, as they can access higher wind speeds further
        offshore, enhancing energy yields and reducing visual impacts on coastal
        communities.
      </p>
      <h3>
        4. UK Confirms Ban on New North Sea Drilling Licences Amid Clean Energy
        Transition
      </h3>
      <p>
        The UK government has confirmed a ban on new drilling licenses and
        proposed easing tax burdens on the oil and gas sector. From 2030, the
        windfall tax on North Sea drillers will be replaced with a regime
        linking duties to global energy prices, providing investment stability.
        An eight-week consultation aims to manage the North Sea's transition
        from oil and gas to cleaner energy forms while avoiding mass job
        losses. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        This policy shift reflects the UK's dedication to a sustainable energy
        future. Balancing the phase-out of fossil fuels with economic stability
        is crucial. Clear timelines and support mechanisms will be essential to
        ensure a smooth transition for affected industries and workers.
      </p>
      <h3>
        5. Saipem Collaborates to Deploy STAR 1 Technology in Italian Floating
        Offshore Wind Projects
      </h3>
      <p>
        Saipem has partnered with Divento, led by Plenitude, CDP, and Copenhagen
        Infrastructure Partners, to utilize Saipem's STAR 1 semi-submersible
        floating wind foundations in Italian offshore wind projects. The 7 Seas
        Med project in Sicily aims for an annual output of around 800 GWh, while
        the Ichnusa Wind Power project in Sardinia targets approximately 1.6 TWh
        per year. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The adoption of innovative floating foundation technologies like STAR 1
        is pivotal for harnessing wind resources in deeper waters. Italy's
        commitment to these projects signifies a strategic move to diversify its
        energy mix and enhance energy security.
      </p>
      <h3>
        6. China Announces Major Renewable Energy Projects to Tackle Climate
        Change
      </h3>
      <p>
        China has unveiled several large-scale renewable energy projects,
        including new offshore wind farms and the construction of “new energy
        bases” in desert regions. These initiatives are key components of
        China’s efforts to achieve carbon peaking and carbon neutrality goals.
        Additionally, China plans to pilot low-carbon technologies at coal-fired
        power plants to reduce carbon emissions.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The proposed reforms to the CfD scheme demonstrate the UK's commitment
        to fostering a conducive environment for renewable energy development.
        By addressing financial and regulatory barriers, these changes are
        poised to enhance investor confidence, streamline project timelines, and
        contribute significantly to the nation's decarbonization objectives.
      </p>
      <h3>
        7. Colombia's First Offshore Wind Energy Auction Attracts Global
        Interest
      </h3>
      <p>
        Colombia's initial offshore wind energy auction has attracted
        international interest, with companies from countries including Belgium,
        Britain, China, and Spain, and domestic firms like Ecopetrol, showing
        proposals to develop 69 offshore areas. This auction is a pivotal step
        in President Gustavo Petro's strategy to diversify the country's energy
        sector and lessen dependence on oil and coal. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Colombia's proactive approach to offshore wind development signifies a
        strategic shift towards sustainable energy. This move not only
        diversifies the energy mix but also positions the country as a potential
        leader in renewable energy in the region.
      </p>
      <br />
      <br />
      <br />
      <p>
        As the global offshore wind sector continues to advance, innovation,
        investments, and supportive policies are driving transformative growth.
        At{" "}
        <i>
          <b>WeBridge</b>
        </i>
        , we provide the expertise and insights our partners need to confidently
        navigate this evolving landscape.{" "}
      </p>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
