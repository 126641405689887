import { insight001 } from "./insights/insight-001";
import { insight002 } from "./insights/insight-002";
import { insight003 } from "./insights/insight-003";
import { insight004 } from "./insights/insight-004";
import { insight005 } from "./insights/insight-005";
import { insight006 } from "./insights/insight-006";
import { insight007 } from "./insights/insight-007";
import { insight008 } from "./insights/insight-008";
import { insight009 } from "./insights/insight-009";
import { insight010 } from "./insights/insight-010";
import { insight011 } from "./insights/insight-011";
import { insight012 } from "./insights/insight-012";
import { insight013 } from "./insights/insight-013";
import { insight014 } from "./insights/insight-014";
import { insight015 } from "./insights/insight-015";
import { insight016 } from "./insights/insight-016";
import { insight017 } from "./insights/insight-017";
import { insight018 } from "./insights/insight-018";
import { insight019 } from "./insights/insight-019";
import { insight020 } from "./insights/insight-020";
import { insight021 } from "./insights/insight-021";
import { insight022 } from "./insights/insight-022";
import { insight023 } from "./insights/insight-023";
import { insight024 } from "./insights/insight-024";
import { insight025 } from "./insights/insight-025";
import { insight026 } from "./insights/insight-026";
import { insight027 } from "./insights/insight-027";
import { insight028 } from "./insights/insight-028";
import { insight029 } from "./insights/insight-029";
import { insight030 } from "./insights/insight-030";
import { insight031 } from "./insights/insight-031";
import { insight032 } from "./insights/insight-032";
import { insight033 } from "./insights/insight-033";
import { insight034 } from "./insights/insight-034";
import { insight035 } from "./insights/insight-035";
import { insight036 } from "./insights/insight-036";
import { insight037 } from "./insights/insight-037";
import { insight038 } from "./insights/insight-038";
import { insight039 } from "./insights/insight-039";

export const events = [
  insight021,
  insight022,
  insight020,
  insight019,
  insight017,
  insight016,
  insight012,
  insight010,
];
export const weekly = [
  insight039,
  insight038,
  insight037,
  insight036,
  insight035,
  insight033,
  insight032,
  insight031,
  insight030,
  insight029,
  insight028,
  insight027,
  insight026,
];
export const insights = [
  insight034,
  insight025,
  insight024,
  insight023,
  insight018,
  insight015,
  insight014,
  insight013,
  insight011,
  insight009,
  insight008,
  insight007,
  insight006,
  insight005,
  insight004,
  insight003,
  insight002,
  insight001,
];
