import Insight from "../insightClass";
import React from "react";
import { insight34Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight034 = new Insight(
  "24 February 2025",
  {
    pageTitle: "How does the EU ETS impact offshore wind developers?",
    metaDescription:
      "WeBridge Insights, How does the EU ETS impact offshore wind developers?",
    keyWords:
      "European Offshore Wind Market development, How does the EU ETS impact offshore wind developers?",
  },
  {
    src: insight34Media[0],
    alt: "offshore wind turbine",
  },
  "How does the EU ETS impact offshore wind developers?",
  "The EU ETS has the potential to affect the profitability of offshore wind projects all over the world. But there are ways to mitigate the impact.",
  `The European Union Emissions Trading System (EU ETS) is a carbon trading scheme. Essentially it taxes businesses that release excess greenhouse gas emissions. With the EU ETS recently expanding to include emissions from shipping, offshore wind developers must understand how the scheme functions in order to preserve the commercial viability of development. `,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>How does the EU ETS impact offshore wind developers?</h1>
      <h2>
        The EU ETS has the potential to affect the profitability of offshore
        wind projects all over the world. But there are ways to mitigate the
        impact.
      </h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>24 February 2025</p>
      <br />
      <p>
        The European Union Emissions Trading System (EU ETS) is a carbon trading
        scheme. Essentially it taxes businesses that release excess greenhouse
        gas emissions. With the EU ETS recently expanding to include emissions
        from shipping, offshore wind developers must understand how the scheme
        functions in order to preserve the commercial viability of development.
      </p>
      <p>There’s an explainer below. But first…</p>

      <h3>Too busy? Skip the reading. </h3>
      <p>
        The EU ETS is complicated and highly nuanced. As experts in the
        international supply chain for offshore wind, we can help you understand
        exactly how the EU ETS affects your business. For clear insight,{" "}
        <a
          href="https://windenergybridge.com/contact-us"
          target="_blank"
          rel="noreferrer"
        >
          get in touch
        </a>{" "}
        to arrange a call.
      </p>
      <p>Okay, on with the article.</p>

      <h3>What is the EU ETS?</h3>
      <p>
        Set up in 2005, the EU ETS was the world's first international emissions
        trading system designed to give businesses a financial imperative to
        reduce greenhouse gas emissions. The scheme places a threshold on the
        amount of greenhouse gas businesses are allowed to emit. Any businesses
        that exceed their limit must purchase additional emissions credits – a
        de facto tax on emissions.{" "}
      </p>
      <p>
        Recently the scope of the EU ETS has expanded to include emissions from
        shipping. The financial burden of excess emissions falls on ship owners.
        But it of course incentivises them to increase fees for chartering
        ships, which can impact offshore wind projects.{" "}
      </p>
      <p>
        The ETS objective is to{" "}
        <a
          href="https://climate.ec.europa.eu/eu-action/transport/reducing-emissions-shipping-sector/faq-maritime-transport-eu-emissions-trading-system-ets_en"
          target="_blank"
          rel="noreferrer"
        >
          reduce emissions by 62% between 2005 and 2030
        </a>
        .
      </p>

      <h3>When does the EU ETS come into force?</h3>
      <p>
        Passenger and cargo ships of 5,000 gross tonnes (GT) and up – e.g. those
        transporting offshore wind infrastructure – became part of the EU ETS in
        2024. From 2027, offshore ships of 5,000 GT or larger – e.g. those
        installing offshore wind infrastructure – will also be regulated by the
        scheme.{" "}
      </p>
      <p>
        The decision on adding cargo ships of 400-4999 GT to EU ETS scheme is
        currently under consideration.{" "}
      </p>
      <p>
        The UK has a separate (but similar) emissions trading scheme, which is
        due to be brought in from 2026. More on that later.
      </p>

      <h3>What does the EU ETS measure?</h3>
      <p>
        The EU ETS measures{" "}
        <a
          href="https://climate.ec.europa.eu/eu-action/transport/reducing-emissions-shipping-sector/faq-maritime-transport-eu-emissions-trading-system-ets_en"
          target="_blank"
          rel="noreferrer"
        >
          carbon dioxide, methane and nitrous oxide emissions
        </a>
        – though the latter two will only be measured from 2026.{" "}
      </p>
      <p>
        In 2025 shipping businesses must pay for 40% of their emissions reported
        in 2024.
      </p>
      <p>In 2026 they must pay for 70% of their emissions reported in 2025.</p>
      <p>
        And from 2027 onwards they must pay for 100% of their reported
        emissions.
      </p>
      <p>
        The financial burden falls on ship owners but can be passed on to the
        charterer – placing upward pressure on the offshore wind industry as a
        whole.{" "}
      </p>

      <h3>How does the EU ETS work in practice?</h3>
      <p>
        The EU ETS is what’s known as a cap-and-trade system. A cap defines the
        total amount of greenhouse gases that can be emitted by organisations
        covered by the system – known as ‘installations’.{" "}
      </p>
      <p>
        Each year eligible installations are given free credits or ‘allowances’,
        which effectively set a maximum amount of emissions that an organisation
        can produce without incurring fees. Each installation’s cap is reduced
        annually in line with the EU’s climate targets.{" "}
      </p>
      <p>
        If an installation exceeds its allowance, they must purchase the
        requisite amount of credits to offset their emissions. Emission
        allowances are auctioned, and companies can buy and sell them through
        secondary markets.
      </p>
      <p>
        Installations that don’t use all of their free allowances can sell them
        to other installations or save them for use in future years.{" "}
      </p>

      <h3>What are the financial implications of the EU ETS?</h3>
      <p>
        The cost of purchasing emissions credits is calculated by multiplying
        emissions per tonne by the cost of carbon. This price fluctuates.
      </p>
      <p>
        The average EU ETS carbon price is expected to be €84.4 per metric ton
        of CO2 during the period 2022 to 2025, but is projected to rise to
        almost €100 per metric ton of CO2 during the period 2026 to 2030.
      </p>
      <p>
        The{" "}
        <a
          href="https://climate.ec.europa.eu/eu-action/transport/reducing-emissions-shipping-sector/faq-maritime-transport-eu-emissions-trading-system-ets_en"
          target="_blank"
          rel="noreferrer"
        >
          non-compliance penalty
        </a>{" "}
        is currently €100 for each tonne of emissions not declared. Names of the
        penalised companies will also be distributed on a public register.{" "}
      </p>

      <h3>Shipping routes can make a big difference to emissions</h3>
      <p>
        The EU ETS is a global concern – affecting eligible vessels that travel
        in, out and within EU territorial waters. However, the emissions
        activity that must be declared under ETS depends on where the journey
        begins and ends.{" "}
      </p>
      <p>Here’s an example. </p>
      <p>
        If a vessel is sailing between two European ports such as Valencia in
        Spain and Gioia Tauro in Italy, then 100% of the vessel’s emissions will
        be covered by the EU ETS. However, if the vessel travelled to Germany
        from China – only 50% of the ship’s emissions for that journey would
        need to be declared under the ETS.
      </p>
      <p>
        For offshore vessels – i.e. those used in the surveying and development
        of offshore wind sites – the ETS covers journeys to ports to load or
        unload cargo as well as stops to take on or relieve crew. Stops for
        refuelling, obtaining supplies, ship repairs, ship-to-ship transfers,
        adverse weather, search and rescue or because the vessel is in distress
        are exempt from the ETS rules.{" "}
      </p>

      <h3>Mitigating the impact of the EU ETS</h3>
      <p>
        With the EU ETS expanding to cover shipping, there’s now a financial
        imperative for ship manufacturers, owners and operators to develop
        technologies that reduce a vessel’s overall emissions and emissions
        liability. It also creates demand for cleaner and more efficient
        shipping fuels.{" "}
      </p>
      <p>
        But there are potentially other ways to lessen the impact of the EU ETS.
      </p>
      <p>
        <a
          href="https://www.ashurst.com/en/insights/uk-ets-to-be-expanded-to-cover-maritime-emissions-your-questions-answered/"
          target="_blank"
          rel="noreferrer"
        >
          The UK has its own emissions trading scheme
        </a>
        , due to come into operation in 2026. However it’s thought the primary
        function of the UK ETS will be to cover domestic shipping journeys: e.g.
        journeys that begin and end in a UK port.{" "}
      </p>
      <p>
        Currently – and this is the crucial bit – there are no plans under the
        UK ETS to capture emissions from international journeys. That means
        journeys to Europe that started from China or the US could bypass EU ETS
        obligations as long as the journey ends in the UK.{" "}
      </p>
      <p>
        Businesses governed by the EU ETS can apply for grants to support their
        emission reduction efforts. The funds raised from the EU ETS are placed
        into an{" "}
        <a
          href="https://climeon.com/eu-ets-shipping-carbon-tax-announced/"
          target="_blank"
          rel="noreferrer"
        >
          Innovation Fund
        </a>{" "}
        to support businesses with decarbonisation of the maritime industry.{" "}
      </p>
      <p>
        Finally, developers can mitigate the impact of the EU ETS by updating
        their pricing strategies, exploring different international partners or
        shipping routes and exploring long-term shipping contracts to manage
        cost uncertainty.{" "}
      </p>

      <h3>Ask us something. Or see how we could help you. </h3>
      <p>
        Here at WeBridge, we have a deep understanding of all aspects of the
        international supply chain for the offshore wind sector. You can ask us
        for advice. And we can also broker conversations between international
        partners.{" "}
        <a
          href="https://windenergybridge.com/about-us"
          target="_blank"
          rel="noreferrer"
        >
          Find out more about what we do
        </a>
        . Or get in touch to{" "}
        <a
          href="https://windenergybridge.com/contact-us"
          target="_blank"
          rel="noreferrer"
        >
          start a conversation
        </a>
        .
      </p>

      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
