import React from "react";
import "./InsightsPage.scss";
import { backgroundArtArray } from "../../assets/assetReference";
import ImgWhiteTemplate from "../../templates/ImgWhiteTemplate/ImgWhiteTemplate";
import { events, insights, weekly } from "../../data/insight/insightsExport";
import Insight from "../../data/insight/insightClass";
import { Helmet } from "react-helmet";

function HelmetContainer() {
  return (
    <Helmet>
      <title>Insights | WeBridge</title>
      <meta
        name="description"
        content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our insights."
      />
      <meta
        name="keywords"
        content="WeBridge, wind energy bridge, offshore wind energy, offshore wind developers, offshore wind suppliers, insights, articles, analysis"
      />
    </Helmet>
  );
}
export function InsightsIngishtsPage() {
  return (
    <>
      <HelmetContainer />
      <div className={"insights-page"}>
        <ImgWhiteTemplate
          backgroundImg={{
            src: backgroundArtArray["insights"],
            alt: "newspaper background for decoration",
          }}
          h1="WeBridge Articles"
          OtherContent={<OtherContent content={insights} title="ARTICLES" />}
        />
      </div>
    </>
  );
}
export function InsightsWeeklyPage() {
  return (
    <>
      <HelmetContainer />
      <div className={"insights-page"}>
        <ImgWhiteTemplate
          backgroundImg={{
            src: backgroundArtArray["weekly"],
            alt: "newspaper background for decoration",
          }}
          h1=""
          OtherContent={<OtherContent content={weekly} title="WEEKLY" />}
        />
      </div>
    </>
  );
}
export function InsightsEventsPage() {
  return (
    <>
      <HelmetContainer />
      <div className={"insights-page"}>
        <ImgWhiteTemplate
          backgroundImg={{
            src: backgroundArtArray["events"],
            alt: "newspaper background for decoration",
          }}
          h1="WeBridge Events"
          OtherContent={<OtherContent content={events} title="EVENTS" />}
        />
      </div>
    </>
  );
}

function OtherContent({ content, title }) {
  return (
    <div className="other-content">
      <p>WEBRIDGE INSIGHTS {title}</p>
      {content.map((i, index) => {
        const modifiedInsight = new Insight(
          i.date,
          i.seo,
          i.img,
          i.title,
          i.sub,
          i.preview,
          i.jsx
        );
        modifiedInsight.heading = "h2";
        return modifiedInsight.previewJSX;
      })}
    </div>
  );
}
