import Insight from "../insightClass";
import React from "react";
import { insight35Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight035 = new Insight(
  "28 February 2025",
  {
    pageTitle: "Global Offshore Wind Weekly",
    metaDescription: "WeBridge Insights, Global Offshore Wind Weekly",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind Weekly",
  },
  {
    src: insight35Media[0],
    alt: "offshore wind turbine",
  },
  "Global Offshore Wind Weekly (2025-02-28)",
  "",
  `Copenhagen Infrastructure Partners (CIP) has agreed to purchase the 480 MW Morecambe offshore wind project from COBRA Group and Flotation Energy. Located off Lancashire's coast, the project aims to power over 500,000 homes. Flotation Energy will remain as a development partner. Financial details were not disclosed.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>Global Offshore Wind Weekly (2025-02-28)</h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>28 February 2025</p>
      <br />
      <p>
        Welcome to this week's <i>WeBridge Insights</i>, where we provide you
        with the latest updates and key developments from the global offshore
        wind market.
      </p>
      <br />
      <br />
      <br />
      <p>
        At <b>WeBridge</b>, we deliver forward-thinking analysis and expert
        commentary to keep you informed in this fast-evolving industry. Here's
        our roundup of the top stories this week, complete with professional
        insights to help you stay ahead:
      </p>

      <h3>
        1. Copenhagen Infrastructure Partners Acquires UK's Morecambe Offshore
        Wind Project
      </h3>
      <p>
        Copenhagen Infrastructure Partners (CIP) has agreed to purchase the 480
        MW Morecambe offshore wind project from COBRA Group and Flotation
        Energy. Located off Lancashire's coast, the project aims to power over
        500,000 homes. Flotation Energy will remain as a development partner.
        Financial details were not disclosed. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        CIP's acquisition of the Morecambe project underscores the UK's
        attractiveness for offshore wind investments. This move aligns with the
        UK's goal to achieve up to 50 GW of offshore wind capacity by 2030,
        highlighting the sector's robust growth and the strategic importance of
        collaborative partnerships in advancing renewable energy infrastructure.
      </p>
      <h3>2. Saipem and Subsea 7 Announce Merger to Expand Offshore Fleet</h3>
      <p>
        Engineering firms Saipem and Subsea 7 have announced plans to merge,
        aiming to combine their fleets to enhance efficiency and reduce costs in
        the offshore energy sector. The unified entity will operate
        approximately 60 vessels, focusing on offshore drilling, installation,
        and decommissioning. Regulatory approvals are anticipated by the second
        half of 2026. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The merger between Saipem and Subsea 7 represents a strategic
        consolidation in the offshore services market, likely driven by the need
        to optimize resources amidst industry challenges. This union is poised
        to offer integrated solutions, potentially setting a precedent for
        future collaborations in the sector.
      </p>
      <h3>
        3. UK Energy Regulator Ofgem Opens Bidding for £4 Billion Transmission
        Links
      </h3>
      <p>
        Ofgem, the UK's energy regulator, has invited investors to bid on
        electricity transmission links valued at £4 billion. These links will
        connect RWE's Sofia wind farm in the North Sea to the North Yorkshire
        electricity grid, aiming to supply power to 1.2 million homes. This
        initiative seeks to attract investment and bolster the UK's clean energy
        infrastructure. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Ofgem's tender for substantial transmission assets reflects the UK's
        proactive approach to enhancing its renewable energy infrastructure. By
        facilitating private investment in critical grid connections, the UK is
        addressing potential bottlenecks in energy distribution, thereby
        supporting the seamless integration of offshore wind energy into the
        national grid.
      </p>
      <h3>
        4. EDPR Reports Unexpected Loss Due to Impairments in U.S. and Colombia
      </h3>
      <p>
        EDP Renováveis (EDPR), a leading wind power producer, reported a net
        loss of €556 million in 2024, attributed to significant impairments
        related to U.S. offshore projects and the exit from Colombian wind
        farms. Despite a 4% increase in electricity sales, these one-off charges
        impacted overall profitability. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        EDPR's financial setback highlights the complexities and risks
        associated with international renewable energy investments. Factors such
        as regulatory changes, market volatility, and project-specific
        challenges underscore the necessity for thorough due diligence and
        adaptive strategies when operating across diverse geopolitical
        landscapes.
      </p>
      <h3>
        5. World Bank and Lenders Criticize Pakistan's Renegotiation of
        Renewable Contracts
      </h3>
      <p>
        A consortium of eight development finance institutions, including the
        World Bank's IFC and the Asian Development Bank, has criticized
        Pakistan's unilateral renegotiation of wind and solar power contracts.
        The group argues that this move undermines investor confidence and could
        deter future investments in the country's renewable energy sector. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Pakistan's decision to renegotiate renewable energy contracts
        unilaterally poses significant risks to its investment climate. Such
        actions can erode trust among international financiers and developers,
        potentially stalling the country's progress toward sustainable energy
        goals. Maintaining transparent and consistent policies is crucial for
        attracting and retaining investment in the renewable sector.
      </p>
      <h3>
        6. UK Government Proposes Reforms to Expedite Green Energy Projects
      </h3>
      <p>
        The UK government has unveiled plans to reform the Contracts for
        Difference (CfD) scheme, aiming to accelerate green energy projects,
        particularly offshore wind farms. Proposed changes include relaxing
        planning consent criteria and extending CfD contracts beyond 15 years,
        providing developers with greater certainty and supporting the UK's
        clean energy ambitions. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        The proposed reforms to the CfD scheme demonstrate the UK's commitment
        to fostering a conducive environment for renewable energy development.
        By addressing financial and regulatory barriers, these changes are
        poised to enhance investor confidence, streamline project timelines, and
        contribute significantly to the nation's decarbonization objectives.
      </p>
      <h3>7. Ørsted Replaces CEO Amidst Financial Challenges in U.S. Market</h3>
      <p>
        Ørsted, the world's largest offshore wind developer, has appointed
        Rasmus Errboe as the new CEO, effective February 1, following
        significant writedowns in its U.S. operations due to high interest rates
        and market uncertainties. The company faces challenges including supply
        chain issues and regulatory changes, notably the suspension of new
        offshore wind leases under the current U.S. administration. 
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        Leadership changes at Ørsted reflect the broader challenges confronting
        the offshore wind industry, particularly in the U.S. market. Navigating
        financial headwinds, policy shifts, and operational hurdles requires
        adaptive leadership and strategic recalibration to sustain growth and
        maintain a competitive edge in the evolving renewable energy landscape.
      </p>

      <br />
      <br />
      <br />
      <p>
        As the global offshore wind sector continues to advance, innovation,
        investments, and supportive policies are driving transformative growth.
        At{" "}
        <i>
          <b>WeBridge</b>
        </i>
        , we provide the expertise and insights our partners need to confidently
        navigate this evolving landscape.{" "}
      </p>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
