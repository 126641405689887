import React, { useContext, useEffect } from "react";
import "./HomePage.scss";
import ImgRedBannerTemplate from "../../templates/ImgRedBannerTemplate/ImgRedBannerTemplate";
import {
  iconArray,
  windmillPhotosArray,
  windmillVideosArray,
} from "../../assets/assetReference";
import { bannerContent, companySellingPoint } from "../../data/content/content";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { GlobalContext } from "../../context/globalContext";
import { NavLink } from "react-router-dom";
import { ContactEnquiry } from "../../components/Contact/Contact";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";
import { Helmet } from "react-helmet";
import { events, insights, weekly } from "../../data/insight/insightsExport";
import Insight from "../../data/insight/insightClass";

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>Wind Energy Bridge</title>
        <meta
          name="description"
          content="WeBridge is a consulting firm bridging European offshore wind developers and international suppliers, providing expert services for the renewable energy industry. Learn more about our wind energy consulting services."
        />
        <meta
          name="keywords"
          content="WeBridge, wind energy bridge, offshore wind energy, offshore wind developers, offshore wind suppliers"
        />
      </Helmet>
      <div className={"home-page-container"}>
        <ImgRedBannerTemplate
          backgroundImg={{
            src: windmillVideosArray[0],
            alt: "a video of many offshore windmill turbine generators, video zooms out",
            type: "video",
          }}
          // backgroundImg={{ src: windmillPhotosArray[2], alt: "offshore windmill photo" }}
          h1={["Working Together", "for a Sustainable Future"]}
          redBannerContent={[
            "We are WeBridge",
            "Connecting European Offshore Wind Projects with International Supply Chains.",
          ]}
          redBannerLink="/about-us"
          OtherContent={<OtherContent />}
          num={3}
          // delay={1.5}
          duration={2}
        />
      </div>
    </>
  );
}

const img = [
  windmillPhotosArray[9],
  windmillPhotosArray[8],
  windmillPhotosArray[10],
  windmillPhotosArray[1],
];

function OtherContent() {
  const { isSmallScreen } = useContext(GlobalContext);
  return (
    <div className="other-content">
      <div className="block">
        <h2>WHY WEBRIDGE</h2>
        <div className="company-features">
          {companySellingPoint.map((i, index) => {
            return (
              <SlideUpAnimation
                triggerOnce={true}
                // initial={index % 2 === 0 ? "-100px" : "100px"}
                transition={{ delay: index * 1 }}
              >
                <div className="feature">
                  <img src={iconArray[i.icon]} alt={i.title} />
                  <div>
                    <h3>{i.title}</h3>
                    <p>{i.desc}</p>
                  </div>
                </div>
              </SlideUpAnimation>
              // <SlideFromSlideAnimation
              //   triggerOnce={true}
              //   initial={index % 2 === 0 ? "-100px" : "100px"}
              // >
              //   {(index % 2 === 0 || isSmallScreen) && (
              //     <img src={img[index]} alt="windmill photo" />
              //   )}
              //   <div>
              //     <h3>{i.title}</h3>
              //     <p>{i.desc}</p>
              //   </div>
              //   {index % 2 !== 0 && !isSmallScreen && (
              //     <img src={img[index]} alt="windmill photo" />
              //   )}
              // </SlideFromSlideAnimation>
            );
          })}
        </div>
      </div>

      <div className="block service-block">
        <SlideUpAnimation triggerOnce={false}>
          <h2>SERVICES</h2>
          <div className="service-container">
            <div className="service developers">
              <h3>For Developers</h3>
              <div>
                <p>{bannerContent.forDevelopers}</p>
                <NavLink to="/services/for-developers">
                  <span>Learn More</span>{" "}
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </NavLink>
              </div>
            </div>
            <div className="service suppliers">
              <h3>For Suppliers</h3>
              <div>
                <p>{bannerContent.forSuppliers}</p>
                <NavLink to="/services/for-suppliers">
                  <span>Learn More</span>{" "}
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </NavLink>
              </div>
            </div>
            <div className="service investors">
              <h3>For Investors</h3>
              <div>
                <p>{bannerContent.forInvestor}</p>
                <NavLink to="/services/for-investors">
                  <span>Learn More</span>{" "}
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </NavLink>
              </div>
            </div>
            <div className="service insurers">
              <h3>For Insurers</h3>
              <div>
                <p>{bannerContent.forInsurers}</p>
                <NavLink to="/services/for-insurers">
                  <span>Learn More</span>{" "}
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </SlideUpAnimation>
      </div>
      <div className="block insight-block">
        <h2>WEBRIDGE INSIGHTS</h2>
        <div className="insight-small-block">
          <p className="insight-title">Articles</p>
          {insights.map((i, index) => {
            if (index > 2) return <></>;
            const modifiedInsight = new Insight(
              i.date,
              i.seo,
              i.img,
              i.title,
              i.sub,
              i.preview,
              i.jsx
            );
            modifiedInsight.heading = "h3";
            return modifiedInsight.previewJSX;
          })}
          <button>
            <NavLink to="/insights/articles">Learn More</NavLink>
          </button>
        </div>
        <div className="insight-small-block">
          <p className="insight-title">Weekly</p>
          {weekly.map((i, index) => {
            if (index > 2) return <></>;
            const modifiedInsight = new Insight(
              i.date,
              i.seo,
              i.img,
              i.title,
              i.sub,
              i.preview,
              i.jsx
            );
            modifiedInsight.heading = "h3";
            return modifiedInsight.previewJSX;
          })}
          <button>
            <NavLink to="/insights/weekly">Learn More</NavLink>
          </button>
        </div>
        <div className="insight-small-block">
          <p className="insight-title">Events</p>
          {events.map((i, index) => {
            if (index > 2) return <></>;
            const modifiedInsight = new Insight(
              i.date,
              i.seo,
              i.img,
              i.title,
              i.sub,
              i.preview,
              i.jsx
            );
            modifiedInsight.heading = "h3";
            return modifiedInsight.previewJSX;
          })}
          <button>
            <NavLink to="/insights/events">Learn More</NavLink>
          </button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="block contact-block">
        <h2>CONTACT US</h2>
        <ContactEnquiry />
      </div>
    </div>
  );
}

function SlideFromSlideAnimation(props) {
  const [ref, inView] = useInView({
    triggerOnce: props.triggerOnce,
    rootMargin: props.rootMargin || "-10% 0px",
  });

  const { isMobile } = useContext(GlobalContext);

  if (isMobile) {
    return <div className="feature">{props.children}</div>;
  }

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: props.initial || 0 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1, ease: "easeOut" }}
      className="feature"
    >
      {props.children}
    </motion.div>
  );
}
