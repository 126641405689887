import Insight from "../insightClass";
import React from "react";
import { insight30Media } from "../../../assets/assetReference";
import { ContactSubscribe } from "../../../components/Contact/Contact";

export const insight030 = new Insight(
  "31 January 2025",
  {
    pageTitle: "Global Offshore Wind Weekly",
    metaDescription: "WeBridge Insights, Global Offshore Wind Weekly",
    keyWords:
      "European Offshore Wind Market development, Global Offshore Wind Weekly",
  },
  {
    src: insight30Media[0],
    alt: "offshore wind turbine",
  },
  "Global Offshore Wind Weekly (2025-01-31)",
  "",
  `A former coal-handling port in North Ayrshire, Scotland, is set to be transformed into a £20 million factory dedicated to producing subsea cables essential for transmitting renewable energy. This initiative aims to revitalize economically challenged areas and bolster the UK's renewable energy supply chain.`,
  (
    <div>
      <strong>WeBridge Insights</strong>
      <h1>Global Offshore Wind Weekly (2025-01-31)</h1>
      <h2></h2>
      <p style={{ opacity: "0.4", marginTop: "-70px" }}>31 January 2025</p>
      <br />
      <p>
        Welcome to this week's <i>WeBridge Insights</i>, where we provide you
        with the latest updates and key developments from the global offshore
        wind market.
      </p>
      <br />
      <br />
      <br />
      <p>
        At <b>WeBridge</b>, we deliver forward-thinking analysis and expert
        commentary to keep you informed in this fast-evolving industry. Here's
        our roundup of the top stories this week, complete with professional
        insights to help you stay ahead:
      </p>

      <h3>
        1. Scotland Invests £20 million in Subsea Cable Manufacturing Facility
      </h3>
      <p>
        A former coal-handling port in North Ayrshire, Scotland, is set to be
        transformed into a £20 million factory dedicated to producing subsea
        cables essential for transmitting renewable energy. This initiative aims
        to revitalize economically challenged areas and bolster the UK's
        renewable energy supply chain. 
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        This substantial investment underscores Scotland's commitment to
        enhancing its renewable energy infrastructure. By focusing on domestic
        manufacturing of subsea cables, the UK aims to mitigate supply chain
        dependencies and support its ambitious decarbonization targets.
      </p>

      <h3>
        2. NESO and GB Transmission Owners Propose Temporary Pause on
        Electricity Connection Applications
      </h3>
      <p>
        The National Energy System Operator (NESO) and Great Britain’s
        Transmission Owners have suggested a temporary halt in processing
        electricity connection applications to facilitate the transition to the
        upcoming Connections Reform, aimed at streamlining application
        processes.
      </p>
      <p>
        <b>WeBridge Insights:</b>
      </p>
      <p>
        This proposed pause reflects the industry's recognition of the need to
        optimize connection processes to accommodate the rapid expansion of
        renewable energy projects. Streamlining these procedures is essential
        for efficient grid integration.
      </p>
      <h3>
        3. Norway's Sovereign Wealth Fund Maintains Commitment to Renewable
        Investments
      </h3>
      <p>
        Despite a -10% return on renewable assets in 2024, Norway's $1.8
        trillion wealth fund remains committed to its renewable energy
        investments, including stakes in offshore wind farms. The fund views
        these investments as prudent for long-term gains and an orderly energy
        transition. 
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        The steadfast approach of Norway's wealth fund underscores the
        importance of a long-term perspective in renewable energy investments.
        Such commitment from significant investors is crucial for the sustained
        growth and stability of the offshore wind sector.
      </p>
      <h3>4. Portugal Announces Plans for 10 GW Offshore Wind Cluster</h3>
      <p>
        Portugal's government has unveiled intentions to establish a cluster of
        offshore wind farms off its Atlantic coast, targeting a future capacity
        of around 10 GW. The plan includes approving four oceanic zones and
        holding the first offshore license auction this year.
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        Portugal's ambitious plan reflects a strategic move to harness its
        extensive Atlantic coastline for renewable energy. Developing a
        significant offshore wind cluster will not only contribute to energy
        independence but also position Portugal as a key player in the European
        renewable energy market.
      </p>
      <h3>
        5. Sceirde Rocks Wind Farm Submits Planning Application for 450 MW
        Offshore Project
      </h3>
      <p>
        Sceirde Rocks Wind Farm has officially submitted a planning application
        to An Bord Pleanála for a 450 MW offshore wind project off Ireland's
        west coast. This submission marks a significant step forward in
        harnessing Ireland's offshore wind potential.
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        The progression of the Sceirde Rocks project highlights Ireland's
        growing focus on offshore wind as a key component of its renewable
        energy strategy. The 450 MW capacity will significantly contribute to
        Ireland's clean energy goals and bolster its position in the European
        offshore wind market.
      </p>
      <h3>
        6. BOEM Launches Environmental Review of Vineyard Mid-Atlantic Offshore
        Wind Project
      </h3>
      <p>
        The Bureau of Ocean Energy Management (BOEM) has initiated an
        environmental review for the proposed Vineyard Mid-Atlantic Offshore
        Wind Energy Project, located in federal waters off New York and New
        Jersey. This review is a critical step in the project's regulatory
        process.
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        BOEM's commencement of the environmental review signifies the project's
        advancement and the federal government's support for offshore wind
        development in the Mid-Atlantic region. This project has the potential
        to provide substantial renewable energy to densely populated areas,
        aiding in regional decarbonization efforts.
      </p>
      <h3>7. Ørsted Completes Turbine Installation at Borkum Riffgrund 3</h3>
      <p>
        Ørsted has successfully installed the final turbine at the Borkum
        Riffgrund 3 offshore wind farm in the German North Sea. This milestone
        brings the project closer to full operational status, enhancing
        Germany's renewable energy capacity.
      </p>
      <p>
        <b>WeBridge Insights: </b>
      </p>
      <p>
        The completion of turbine installation at Borkum Riffgrund 3 underscores
        Ørsted's operational efficiency and Germany's commitment to expanding
        its offshore wind infrastructure. Once operational, this wind farm will
        play a significant role in meeting Germany's renewable energy targets
        and reducing reliance on fossil fuels.
      </p>

      <br />
      <br />
      <br />
      <p>
        As the global offshore wind sector continues to advance, innovation,
        investments, and supportive policies are driving transformative growth.
        At{" "}
        <i>
          <b>WeBridge</b>
        </i>
        , we provide the expertise and insights our partners need to confidently
        navigate this evolving landscape.{" "}
      </p>
      <h3 style={{ marginTop: "200px" }}>Subscribe</h3>
      <ContactSubscribe />
    </div>
  )
);
